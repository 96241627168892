@import 'src/styles';

.title_h1 {
  @include title-h1;
}

.title_h2 {
  @include title-h2;
}

.title_h3 {
  @include title-h3;
}
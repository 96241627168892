@import "src/styles/index";

.wrap {
  position: relative;
  display: flex;
  justify-content: center;

  &.disable {
    pointer-events: none;
  }

  .border {
    position: absolute;
    z-index: 1;
    top: -2px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: linear-gradient(
      0deg,
      rgba(137, 114, 168, 0) 40%,
      rgb(84, 59, 245) 100%
    );
  }

  .inner_spin_button {
    position: relative;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: linear-gradient(
      170.31deg,
      rgba(197, 131, 250, 1) 10.88%,
      rgba(197, 131, 250, 0.25) 46.99%,
      rgba(197, 131, 250, 0.1) 80.57%
    );
    z-index: 1;
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding-top: 8px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .spin {
        width: 90%;
        font-family: "Montserrat", sans-serif;
        color: $white;
        font-size: $fontSize34;
        font-weight: bold;
        margin: 8px 0;
        text-align: center;
      }

      .counter {
        width: 30px;
        height: 30px;
        background-color: #52dcaa;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .attempt {
          font-family: "Montserrat", sans-serif;
          color: $white;
          font-size: $fontSize16;
          line-height: 30px;
          font-weight: bold;
        }
      }
    }
  }

  .triangle {
    position: absolute;
    top: -42px;
    border: 15px solid transparent;
    z-index: 2;
    border-bottom: 30px solid rgba(197, 131, 250, 1);
  }
  .triangle_border {
    position: absolute;
    top: -45px;
    border: 15px solid transparent;
    z-index: 1;
    border-bottom: 30px solid rgb(84, 59, 245);
  }
}

.wrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wheel {
  width: 670px;
  height: 670px;
  background-color: rgba(37, 31, 77, 0.3);
  border-radius: 50%;
  border: 40px solid rgba(37, 31, 77, 0.3);
  position: relative;
  overflow: hidden;

  div {
    height: 50%;
    width: 158px;
    position: absolute;
    clip-path: polygon(100% 0, 50% 100%, 0 0);
    transform: translateX(-50%);
    transform-origin: bottom;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    left: 216px;
  } 

}
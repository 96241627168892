@import 'src/styles';

.root {
  border: 0;
  text-decoration: underline;
  color: $primaryColor;

  &:hover {
    text-decoration: none;
  }
}
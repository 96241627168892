@import 'src/styles';

.input {
  caret-color: $inputCaretColor;
  display: flex;
  align-self: flex-start;
  border-width: 1px;
  border-style: solid;
  color: $inputColor;
  font-family: $fontFamily;
  font-size: $fontSize16;
}

.input::placeholder {
  color: $inputColor;
}

.input:focus {
  outline: 0;
}

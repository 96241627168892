@import "./mixin";

.dots {
  @include on-circle($item-count: 34, $circle-size: 630px, $item-size: 10px);
  z-index: 1;
  top: 20px;
  left: 20px;

  div {
    background-color: #52DCAA;
    display: block;
    max-width: 100%;
    border-radius: 50%;
  }
}
.container {
  position: relative;

  .border {
    position: absolute;
    top: -2px;
    width: 671px;
    height: 671px;
    border-radius: 50%;
    background: linear-gradient(90deg, rgba(106, 82, 255, 0) 0%, rgba(106, 42, 187, 0.7) 98%);
    transform: rotate(-110deg);
  }

  .wrap {
    position: absolute;
    top: 0px;
    left: 0;
    transition: 4000ms cubic-bezier(0.3,0.1,0.05,1);
  }
}
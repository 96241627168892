@import "src/styles";

$font: "Montserrat", sans-serif;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  background: linear-gradient(
    0deg,
    rgba(18, 18, 18, 0.8029805672268908) 0%,
    transparent 100%
  );
  opacity: 0;
  transition: 500ms all ease;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .modal {
    position: relative;
    z-index: 10;
    height: 95%;
    width: 90%;
    background: linear-gradient(
      180deg,
      #9e52ff 0%,
      #503d68 55.21%,
      #2c2c2c 100%
    );
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .rays_wrap {
      position: absolute;
      transform: rotate(8deg);
      opacity: 0.5;
    }

    .confetti {
      width: 100%;
      height: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      background-image: image-set(
        "images/confetti.webp" 1x,
        "images/confetti@2x.webp" 2x,
        "images/confetti@3x.webp" 3x
      );
      background-repeat: no-repeat;
    }

    .content {
      position: relative;
      z-index: 14;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 3vh;
        padding: 0 10px;

        .title {
          font-family: $font;
          font-size: $fontSize32;
          white-space: break-spaces;
          color: $white;

          > div {
            max-height: 80px;
          }
        }

        .sub_title {
          margin: 3vh 0 1vh;
          font-family: $font;
          font-weight: 400;
          font-size: 17px;
          text-align: center;
          color: rgba(255, 255, 255, 0.85);
          max-height: 40px;
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0 10vh;

        .button {
          margin-bottom: 2vh;
          background-color: $secondaryColor;
          color: $white;
          font-family: $font;
        }
      }
    }
  }
}

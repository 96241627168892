.buttons_block {
  display: flex;
  justify-content: space-between;
  width: 85%;

  .button {
    border: 1px solid white;
    border-radius: 7px;
    margin: 0 4px;
  }
}

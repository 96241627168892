@import "src/styles";

body {
  background-color: black;
}

.luck_wheel {
  position: fixed;
  margin: 0 auto;
  background-color: black;
  width: 100%;
  max-width: 420px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 200ms linear;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-family: "Montserrat", sans-serif;
      font-weight: $fwstb;
      font-size: $fontSize34;
      white-space: break-spaces;
      padding: 15px;
      color: $white;

      > div {
        max-height: 80px;
      }

      b {
        color: $primaryColor;
      }
    }
  }

  .rays_wrap {
    position: absolute;
    transform: rotate(8deg);
    bottom: 150px;
  }

  .wheel_wrap {
    position: relative;
    z-index: 2;
    bottom: 490px;
    left: -335px;
  }
}

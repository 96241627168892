.sector {
  img {
    margin-top: 30px;
  }

  &:nth-child(odd) {
    background: linear-gradient(171.91deg, #6A52FF 5.42%, #6A2ABB 69.75%);
    box-shadow: 0px 0px 20px rgba(35, 71, 58, 0.3);
  }

  &:nth-child(even) {
    background: linear-gradient(200.2deg, #52DCAA 16.41%, #78AAE6 75.93%);
  }

  &:nth-child(1) {
    left: 50%;
  }

  &:nth-child(2) {
    transform: rotate(30deg);
  }

  &:nth-child(3) {
    transform: rotate(60deg);
  }

  &:nth-child(4) {
    transform: rotate(90deg);
  }

  &:nth-child(5) {
    transform: rotate(120deg);
  }

  &:nth-child(6) {
    transform: rotate(150deg);
  }

  &:nth-child(7) {
    transform: rotate(180deg);
  }

  &:nth-child(8) {
    transform: rotate(210deg);
  }

  &:nth-child(9) {
    transform: rotate(240deg);
  }

  &:nth-child(10) {
    transform: rotate(270deg);
  }

  &:nth-child(11) {
    transform: rotate(300deg)
  }

  &:nth-child(12) {
    transform: rotate(330deg)
  }
}
$nr: 15;
$p: calc(100% / $nr);
$m: repeating-conic-gradient(#000 0% .5*$p, transparent 0% $p);

.rays {
  width: 1000px;
  height: 450px;
  border-radius: 50%;
  background: linear-gradient(transparent, rgb(72, 72, 72));
  -webkit-mask: $m;
  mask: $m;
  pointer-events: none;
}
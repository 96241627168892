.wrap {
  position: absolute;
  z-index: 2;
  bottom: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: blink 1100ms linear infinite;

  @keyframes blink {
    50% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(1);
    }
  }

  &.stop_blink {
    animation: none;
  }

  .spin_button {
    position: absolute;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background-color: #251f4d;
    display: flex;
    justify-content: center;

    .triangle {
      position: absolute;
      top: -85px;
      border: 45px solid transparent;
      border-bottom: 80px solid #251f4d;
    }
  }
}

@import 'src/styles';

.modal {
  transform: translate3d(-50%, 100%, 0);
  transition: all 0.5s ease-in-out;
  position: fixed;
  left: 50%;
  bottom: 0;
  overflow-y: scroll;
  border-radius: 30px;
  margin: 0;
  outline: none;
  z-index: 11;
}

.modal-content-after {
  transform: translate3d(-50%, 0, 0);
}

.modal-content-before {
  transform: translate3d(-50%, 100%, 0);
}

.overlay {
  background-color: rgba(192, 192, 192, 0.55);
  z-index: 20;
  height: 100vh;
  inset: 0;
  position: fixed;
  width: 100%;
  transition: $transition;
}
